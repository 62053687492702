import { Button, Chip, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { get_user_sellers } from "../../../services/user.services";
import { get_empresa_sucursal } from "../../../services/empresa.services";
import { getUser } from "../../../services/auth.services";
import toast from "react-hot-toast";
import { Print } from "@mui/icons-material";

const FiltersReservationSellers = ({
  onSearchData,
  onOpenMetaDialog,
  initialState,
  onLoadData,
  handlePrint,
}) => {
  const ID_PERFIL_SELLER = 6;
  const ID_ADMIN = 4;

  const [dataUser, setDataUser] = useState([]);
  const [loadUser, setLoadUser] = useState(false);

  const [dataSucursales, setDataSucursales] = useState([]);
  const [loadSucursales, setLoadSucursales] = useState(false);
  const [sucursalesSelected, setSucursalesSelected] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const onSearch = (data) => {
    if (sucursalesSelected.length === 0) {
      toast.error("Debe seleccionar una sucursal.");

      return;
    }

    onSearchData({ ...data, userId: Number(data.userId), sucursalesSelected });
  };

  const handleDelete = (value) => {
    const newData = sucursalesSelected.filter((el) => el.value !== value);
    setSucursalesSelected(newData);
  };

  const handleSelectSucursal = (value) => {
    // find if the sucursal is already selected
    const findSucursal = sucursalesSelected.some(
      (el) => Number(el.value) === Number(value)
    );

    // if the sucursal is already selected, do nothing
    if (findSucursal) return;

    // if the sucursal is not selected, add it to the selected sucursals
    const newSucursal = dataSucursales.find(
      (el) => Number(el.value) === Number(value)
    );

    setSucursalesSelected([...sucursalesSelected, newSucursal]);
  };

  const fetchSucursales = async () => {
    let response = await get_empresa_sucursal();
    response = response.find((el) => el.value === getUser().empresa.id_emp);

    return response?.sucursales;
  };

  async function handleGetEmpresas() {
    setLoadSucursales(true);

    let response = await fetchSucursales();
    setDataSucursales(response);

    let responseSurcursaleSelected = response.filter(
      (el) => Number(el.value) === Number(getUser()?.empresa?.id_empsede)
    );

    setSucursalesSelected(responseSurcursaleSelected);

    setLoadSucursales(false);

    return responseSurcursaleSelected;
  }
  async function handleGetUsers(sucursales) {
    setLoadUser(true);

    const response = await get_user_sellers(sucursales);

    const newResponse = response
      .filter(
        (el) => el.tipousu_id === ID_PERFIL_SELLER || el.tipousu_id === ID_ADMIN
      )
      .map((el) => {
        return {
          ...el,
          value: el.usu_id,
          label: el?.usu_nombre.toUpperCase(),
        };
      });

    setDataUser(newResponse || []);
    setLoadUser(false);
  }

  useEffect(() => {
    async function handleFetch() {
      const response = await handleGetEmpresas();
      await handleGetUsers(response);
    }

    handleFetch();
  }, []);

  useEffect(() => {
    if (sucursalesSelected.length > 0) {
      handleGetUsers(sucursalesSelected);
    }
  }, [sucursalesSelected]);

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-3">
          <InputDefault
            onChange={(el) => handleSelectSucursal(el.target.value)}
            type="select"
            disabled={loadSucursales}
            title={"Sucursal"}
            options={dataSucursales}
          />
        </div>
        <div className="col-span-9">
          <Stack direction="row" spacing={1}>
            {sucursalesSelected.map((el) => (
              <Chip
                key={el.value}
                label={el.label}
                variant="outlined"
                onDelete={() => handleDelete(el.value)}
              />
            ))}
          </Stack>
        </div>

        <div className="col-span-3">
          <Controller
            name="userId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type="select"
                title={"Vendedor"}
                options={dataUser}
                disabled={loadUser}
              />
            )}
          />
        </div>

        <div className="col-span-4">
          <Controller
            name="filterMonth"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="month"
                title={"Mes"}
              />
            )}
          />
        </div>
        <div className="col-span-3">
          <Button
            variant="contained"
            color="primary"
            className="ml-auto w-full md:w-auto"
            type="submit"
            disabled={onLoadData}
          >
            Buscar
          </Button>
          <IconButton size="large" color="primary" onClick={handlePrint} className="!ml-2" disabled={onLoadData}>
            <Print />
          </IconButton>
        </div>
        {/* <div className="col-span-12 flex justify-end mb-4">
          <Button
            variant="contained"
            color="secondary"
            className="ml-auto w-full md:w-auto"
            type="button"
            onClick={() => onOpenMetaDialog()}
          >
            Metas
          </Button>
        </div> */}
      </div>
    </form>
  );
};

export default FiltersReservationSellers;
