import { Delete, MusicNoteOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref || false} {...props} />;
});

const DialogPlayList = ({ open, onClose, onDelete, onConfirm, data = [] }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      onClose={onClose}
      maxWidth={"sm"}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Crear playlist</DialogTitle>
      <DialogContent>
        {data.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <MusicNoteOutlined className="text-red-500 text-2xl" />
            <h3 className="text-center text-2xl font-bold text-slate-700 mt-2">
              El playlist esta vacío
            </h3>{" "}
            <p className="text-center text-sm text-slate-500">
              Añade algunas músicas para crear tu lista.
            </p>
          </div>
        )}

        {data.length > 0 && (
          <Table className="w-full text-sm border-collapse border border-slate-200">
            <TableBody>
              {data.map((el, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell className="text-left">
                      <div className="flex items-center justify-between">
                        <img
                          src={el.thumbnails.default.url}
                          alt="thumbnail"
                          className="w-10 h-10 rounded-full"
                          width={el.thumbnails?.default?.width || 50}
                          height={el.thumbnails?.default?.height || 50}
                        />
                        <div className="ml-2 ">
                          <label className="font-semibold text-gray-800">
                            {el.musicName}
                          </label>
                          <p className="text-xs text-gray-600">
                            {el.descriptionMusic}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="text-left">
                      <IconButton
                        color="error"
                        onClick={() => onDelete?.(el.videoId)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="inherit">
          Cerrar
        </Button>
        <Button
          onClick={() => onConfirm?.(data)}
          variant="contained"
          color="success"
          disabled={data.length === 0}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPlayList;
