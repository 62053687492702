import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { Download, OpenInNew } from "@mui/icons-material";
import { Alert, IconButton } from "@mui/material";
import InputDefault from "../../atomic/atoms/input.atoms";
import { getUrlFront } from "../../functions/utilidades.function";
import { getUser } from "../../services/auth.services";
import { getSuggestDj } from "../../services/suggest-dj.services";

const DjSuggestionsPlaylist = (props) => {
  let { socket } = props;

  const [dataSuggestions, setDataSuggetions] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const url = getUrlFront();

  const empId = getUser()?.empresa?.id_emp || 0;
  const LINK_DJ_SUGGESTIONS = `${url}/playlist-dj/${empId}`;

  const dataPlaylist = dataSuggestions.filter(
    (el) => el?.type_suggestdj === "playlist"
  );

  const fetchDataSuggestions = async () => {
    try {
      setLoad(true);
      let response = await getSuggestDj();

      response = response.map((el) => ({
        ...el,
        canBeSelectable: true,
      }));

      setDataSuggetions(response);

      setLoad(false);
    } catch (error) {
      console.error("Error en fetchDataSuggestions: ", error);
    }
  };

  useEffect(() => {
    fetchDataSuggestions();
  }, []);

  useEffect(() => {
    socket.on("suggest-dj-created", async (element) => {
      const { data } = element;

      setDataSuggetions(data);
    });

    return null;
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <div className="flex flex-row justify-between w-96">
            <InputDefault
              title="Link para playlist"
              type="text"
              value={LINK_DJ_SUGGESTIONS}
              readOnly
            />
            <IconButton
              style={{ marginLeft: 5 }}
              color="warning"
              onClick={() => window.open(LINK_DJ_SUGGESTIONS, "_blank")}
            >
              <OpenInNew />
            </IconButton>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4">
          <ContainerInput style={{ padding: 10 }}>
            Número músicas pedidas: {dataPlaylist.length}
          </ContainerInput>
        </div>
        <Alert severity="info" className="col-span-12 md:col-span-8" b>
          <p>
            En esta sección podrás ver todas las recomendaciones creados por los
            usuarios.
          </p>
        </Alert>
        <div className="col-span-12 md:col-span-12">
          <ContainerInput className="p-0 mt-4">
            <CustomTable
              columns={[
                {
                  id: "numbertimes_suggestdj",
                  label: "# veces",
                  minWidth: 80,
                  align: "center",
                  format: (el) => {
                    return <span className="uppercase">{el}</span>;
                  },
                },
                {
                  id: "video_id",
                  label: "Descargar",
                  minWidth: 80,
                  align: "center",
                  format: (el) => {
                    if (!el) {
                      return null;
                    }

                    return (
                      <a
                        href={`https://www.y2mate.com/youtube/${el}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        <Download />
                      </a>
                    );
                  },
                },
                {
                  id: "title_suggestdj",
                  label: "Titulo & Autor",
                  minWidth: 250,
                  align: "left",
                  format: (el) => {
                    return <span className="uppercase">{el}</span>;
                  },
                },
                {
                  id: "link_suggestdj",
                  label: "Link de la música",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return (
                      <a
                        href={el}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {el}
                      </a>
                    );
                  },
                },
                {
                  id: "createdat_suggestdj",
                  label: "Fecha",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    return dayjs(el).utc().format("DD/MM/YYYY HH:mm");
                  },
                },
              ]}
              rows={dataPlaylist}
              load={load}
              error={false}
              pagination={false}
            />
          </ContainerInput>
        </div>
      </div>
    </Template>
  );
};

export default DjSuggestionsPlaylist;
