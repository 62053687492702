import { Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { utils, writeFile } from "xlsx";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { retrieveReservations } from "../../services/dashboard.services";
import {
  get_metaMonth_seller,
  update_metaMonth_seller,
} from "../../services/user.services";
import FiltersReservationSellers from "./ui/filters.reservationsellers";
import TableMetaResume from "./ui/tableMetaResume";

const ReservationSellers = () => {
  const initalDataFilter = {
    userId: 0,
    filterMonth: dayjs().format("YYYY-MM"),
  };

  const [data, setData] = useState({
    result: [],
    totals: {},
  });
  const [load, setLoad] = useState(false);

  const [metaMonth, setMetaMonth] = useState(0);

  const [dataFilter, setDataFilter] = useState(initalDataFilter);

  const onPrintData = () => {
    const DATE = dayjs().format("YYYY-MM-DD");

    let newData = data.result.map((el) => ({
      "Fec. de registro": dayjs(el.createdAt).utc().format("DD/MM/YYYY"),
      "Cant. reservas": el.amountOfReservations,
      "Prom. reservas": el.pax.promReservations.toFixed(1),
      "Total PAX": el.pax.amountTotalPAX,
      [actualMonth]: `${
        el.reservationMonths.actualMonth.value
      } (${el.reservationMonths.actualMonth.prom.toFixed(1)}%)`,
      [actuaMonthPlusOne]: `${
        el.reservationMonths.oneMonth.value
      } (${el.reservationMonths.oneMonth.prom.toFixed(1)}%)`,
      [actuaMonthPlusTwo]: `${
        el.reservationMonths.twoMonth.value
      } (${el.reservationMonths.twoMonth.prom.toFixed(1)}%)`,
      [actuaMonthPlusThree]: `${
        el.reservationMonths.threeMonth.value
      } (${el.reservationMonths.threeMonth.prom.toFixed(1)}%)`,
      Superior: `${
        el.reservationMonths.superiorMonth.value
      } (${el.reservationMonths.superiorMonth.prom.toFixed(1)}%)`,
    }));

    const dataTotal = data.totals[0];

    newData.push({
      "Fec. de registro": "",
      "Cant. reservas": dataTotal.amountOfReservations,
      "Prom. reservas": dataTotal.pax.promReservations.toFixed(1),
      "Total PAX": dataTotal.pax.amountTotalPAX,
      [actualMonth]: `${
        dataTotal.reservationMonths.actualMonth.value
      } (${dataTotal.reservationMonths.actualMonth.prom.toFixed(1)}%)`,
      [actuaMonthPlusOne]: `${
        dataTotal.reservationMonths.oneMonth.value
      } (${dataTotal.reservationMonths.oneMonth.prom.toFixed(1)}%)`,
      [actuaMonthPlusTwo]: `${
        dataTotal.reservationMonths.twoMonth.value
      } (${dataTotal.reservationMonths.twoMonth.prom.toFixed(1)}%)`,
      [actuaMonthPlusThree]: `${
        dataTotal.reservationMonths.threeMonth.value
      } (${dataTotal.reservationMonths.threeMonth.prom.toFixed(1)}%)`,
      Superior: `${
        dataTotal.reservationMonths.superiorMonth.value
      } (${dataTotal.reservationMonths.superiorMonth.prom.toFixed(1)}%)`,
    });

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `Productivad de ventas`);
    writeFile(wb, `${"Productivad de ventas"}-${DATE}.xlsx`);
  };

  const onSearchData = async (value) => {
    setLoad(true);

    setDataFilter(value);

    const response = await retrieveReservations(value);

    if (value.userId > 0) {
      const responseMeta = await get_metaMonth_seller(value);
      const metaValue =
        responseMeta.length > 0 ? responseMeta[0].value_metaseller : 0;
      setMetaMonth(metaValue);
    }

    setLoad(false);

    setData(response);
  };

  const onChangeMetaMonth = async (value) => {
    setMetaMonth(value);
    await update_metaMonth_seller({ ...dataFilter, value });
  };

  const metaAmountTotalPAX =
    data.totals.length > 0 && data.totals[0].pax.amountTotalPAX;
  const metaSaldo = metaMonth - metaAmountTotalPAX;
  const metaAlcanzado =
    metaMonth > 0
      ? `${((metaAmountTotalPAX / metaMonth) * 100).toFixed(2)}%`
      : 0;

  const actualMonth = dayjs(dataFilter.filterMonth).format("MMMM");
  const actuaMonthPlusOne = dayjs(dataFilter.filterMonth)
    .add(1, "month")
    .format("MMMM");
  const actuaMonthPlusTwo = dayjs(dataFilter.filterMonth)
    .add(2, "month")
    .format("MMMM");
  const actuaMonthPlusThree = dayjs(dataFilter.filterMonth)
    .add(3, "month")
    .format("MMMM");

  useEffect(() => {
    onSearchData(initalDataFilter);
  }, []);

  return (
    <Template title="Productivad de ventas">
      <FiltersReservationSellers
        onSearchData={onSearchData}
        initialState={initalDataFilter}
        onLoadData={load}
        handlePrint={onPrintData}
      />

      <ContainerInput className="p-0">
        <CustomTable
          columns={[
            // {
            //   id: "seller",
            //   label: "Vendedor",
            //   minWidth: 130,
            //   align: "left",
            // },
            {
              id: "createdAt",
              label: "Fec. de registro",
              minWidth: 130,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
            {
              id: "amountOfReservations",
              label: "Cant. reservas",
              minWidth: 120,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "pax",
              label: "Prom. reservas",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.promReservations.toFixed(1)}
                  </div>
                );
              },
            },
            {
              id: "pax",
              label: "Total PAX",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-right">{el.amountTotalPAX}</div>;
              },
            },
            {
              id: "reservationMonths",
              label: `${actualMonth}`,
              minWidth: 120,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.actualMonth.value} ({el.actualMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              label: `${actuaMonthPlusOne}`,
              minWidth: 120,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.oneMonth.value} ({el.oneMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              label: `${actuaMonthPlusTwo}`,
              minWidth: 120,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.twoMonth.value} ({el.twoMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              label: `${actuaMonthPlusThree}`,
              minWidth: 120,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.threeMonth.value} ({el.threeMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              label: "SUPERIOR",
              minWidth: 120,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.superiorMonth.value} ({el.superiorMonth.prom.toFixed(1)}
                    %)
                  </div>
                );
              },
            },
          ]}
          rows={data.result}
          rowsFooter={data.totals}
          columnsFooter={[
            // {
            //   id: "seller",
            // },
            {
              id: "createdAt",
            },
            {
              id: "amountOfReservations",
              format: (el) => {
                return <div className="text-right">{el}</div>;
              },
            },
            {
              id: "pax",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.promReservations.toFixed(1)}
                  </div>
                );
              },
            },
            {
              id: "pax",
              format: (el) => {
                return <div className="text-right">{el.amountTotalPAX}</div>;
              },
            },
            {
              id: "reservationMonths",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.actualMonth.value} ({el.actualMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.oneMonth.value} ({el.oneMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.twoMonth.value} ({el.twoMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.threeMonth.value} ({el.threeMonth.prom.toFixed(1)}%)
                  </div>
                );
              },
            },
            {
              id: "reservationMonths",
              format: (el) => {
                return (
                  <div className="text-right">
                    {el.superiorMonth.value} ({el.superiorMonth.prom.toFixed(1)}
                    %)
                  </div>
                );
              },
            },
          ]}
          load={load}
          error={false}
          disableButtons={false}
          pagination={true}
          rowsPerPagination={100}
        />
      </ContainerInput>

      {data.result.length > 0 && !load && (
        <ContainerInput className="mt-2 px-4 w-auto">
          <TableMetaResume
            justBySeller={dataFilter?.userId > 0}
            paxMonth={metaAmountTotalPAX}
            metaMonth={
              <div className="flex justify-end items-center gap-2">
                <Edit fontSize="18" />
                <input
                  type="number"
                  value={metaMonth}
                  onChange={(e) => onChangeMetaMonth(e.target.value)}
                  className="text-right w-[60px] bg-slate-200 text-black"
                />
              </div>
            }
            saldo={metaSaldo}
            alcanzado={metaAlcanzado}
          />
        </ContainerInput>
      )}
    </Template>
  );
};

export default ReservationSellers;
