import { Add, MusicNote, MusicNoteOutlined, Search } from "@mui/icons-material";
import { Alert, Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import InputDefault from "../../atomic/atoms/input.atoms";
import LoadingPage from "../../components/LoadingPage";
import PublicTemplate from "../../components/Template/PublicTemplate";
import { videoSearchAllVideos } from "../../services/youtube.services";
import DialogPlayList from "./ui/DialogPlayList.ui";
import { createSuggestDjPlaylist } from "../../services/suggest-dj.services";

const RenderDjSuggestionsPlaylistClient = () => {
  const { companyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [videoData, setVideosData] = useState([]);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [playlistToDj, setPlaylistToDj] = useState([]);

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      musicName: "",
      linkMusic: "",
      descriptionMusic: "",
      companyId: Number(companyId),
      videoId: "",
      musicDescription: "",
      typeSuggestDj: "live",
    },
  });

  // const musicName = watch("musicName");
  // const debouncedQuery = useDebounce(musicName, 900);

  // Function to search for a video
  const searchVideo = async (data) => {
    const query = data.musicName;

    if (!query || query === "") {
      setVideosData([]);
      return;
    }

    try {
      setLoadingVideo(true);
      const response = await videoSearchAllVideos(query);
      setLoadingVideo(false);

      setVideosData(response);
    } catch (error) {
      setVideosData([]);
    }
  };

  const onDeleteMusic = async (videoId) => {
    setPlaylistToDj((prev) => prev.filter((el) => el.videoId !== videoId));

    let playlistStorage = playlistToDj;
    playlistStorage = playlistStorage.filter((el) => el.videoId !== videoId);

    localStorage.setItem("playlistToDj", JSON.stringify(playlistStorage));
  };

  const onAddMusic = async (data) => {
    setPlaylistToDj((prev) => [...prev, data]);

    let playlistStorage = playlistToDj;
    playlistStorage.push(data);

    localStorage.setItem("playlistToDj", JSON.stringify(playlistStorage));
  };

  const onConfirmPlaylist = async (data) => {
    try {
      setLoading(true);

      const cartUser = JSON.parse(localStorage.getItem("cart_user"));
      const userId = cartUser?.id || null;
      const userName = cartUser?.name_client || null;
      const userLastname = cartUser?.ape_client || null;

      const newData = data.map((el) => ({
        ...el,
        userName,
        userLastname,
        userId,
        companyId,
        typeSuggestDj: "playlist",
      }));

      await createSuggestDjPlaylist(newData);
      setLoading(false);

      setCompleted(true);

      // clear storage & state
      localStorage.removeItem("playlistToDj");
      setPlaylistToDj([]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let playlistStorage =
      JSON.parse(localStorage.getItem("playlistToDj")) || [];

    setPlaylistToDj(playlistStorage);
  }, []);

  if (completed) {
    return (
      <PublicTemplate
        title="Sugiere al DJ"
        companyId={companyId && Number(companyId)}
        headActived={false}
        footerActived={false}
      >
        <div className="flex flex-col items-center justify-center h-screen">
          <h2 className="text-center text-2xl font-bold text-slate-700">
            <MusicNote className="mr-2 text-red-500" />
            Felicidades, tu música fue añadida correctamente a la lista del DJ.
            <MusicNote className="ml-2 text-red-500" />
          </h2>

          <div className="mt-2 text-center text-sm text-slate-500">
            Para volver añadir tienes que esperar 1 hora.
          </div>
        </div>
      </PublicTemplate>
    );
  }

  return (
    <PublicTemplate
      title="Sugiere al DJ"
      companyId={companyId && Number(companyId)}
      headButtons={
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className="!shadow-none !text-white !ml-2"
          onClick={() => setOpenPlaylist(true)}
        >
          <label className="!text-white !text-sm">{playlistToDj.length}</label>
          <MusicNoteOutlined />
        </Button>
      }
    >
      {loading && <LoadingPage open={true} />}

      <h3 className="text-center text-2xl font-bold text-slate-700 mt-2">
        Crea tu lista de recomendaciones para el DJ
      </h3>

      <Alert severity="info" className="mt-5">
        <span className="font-bold">
          <span className="text-red-500">*</span> Porque nos importa tus gustos
          y la pases bien. Arma tu lista de recomendaciones para el DJ.
        </span>
      </Alert>

      <form
        onSubmit={handleSubmit(searchVideo)}
        autoComplete="off"
        className="mb-20"
      >
        <div className="mb-4 mt-4 w-full grid grid-cols-12 gap-3">
          <div className="col-span-12 flex justify-end">
            <Controller
              name="musicName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  type={"text"}
                  title={"Buscar música en Youtube"}
                  required
                />
              )}
            />
            <IconButton
              color="primary"
              className="!bg-slate-200 !text-black !ml-2"
              type="submit"
            >
              <Search fontSize="inherit" />
            </IconButton>
          </div>
          <div className="col-span-12">
            {videoData.length === 0 && loadingVideo === false && (
              <div className="mt-28 mb-28 text-center">
                <MusicNote className="text-red-500 text-2xl" />
                <h1 className="text-center text-2xl font-bold text-slate-700">
                  No se encontraron resultados.
                </h1>
              </div>
            )}

            {loadingVideo ? (
              <div className="mt-4 mb-4 text-center">Cargando...</div>
            ) : (
              videoData.map((el, key) => {
                const videoId = el.id.videoId;
                const ytLink = `https://www.youtube.com/embed/${videoId}`;
                const ytLinkToWatch = `https://www.youtube.com/watch?v=${videoId}`;
                const title = el.snippet.title;
                const description = el.snippet.description;
                const thumbnails = el.snippet.thumbnails;
                const isDisable = playlistToDj.find(
                  (el) => el.videoId === videoId
                );

                return (
                  <div className="mt-2 mb-2" key={key}>
                    <div className="w-full grid grid-cols-12 p-2 border border-slate-200 gap-2">
                      <div className="col-span-12 text-sm">
                        <div className="mb-2">
                          Titulo:{" "}
                          <label className="text-gray-600">{title}</label>
                        </div>
                        <div className="mb-2">
                          Descripción:{" "}
                          <label className="text-gray-600">{description}</label>{" "}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <iframe
                          width="100%"
                          height="150"
                          src={ytLink}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="YouTube Video"
                        ></iframe>
                      </div>
                      <div className="col-span-12 text-center">
                        <Button
                          disabled={isDisable}
                          variant="contained"
                          color="success"
                          className="!shadow-none !text-white !text-xs"
                          onClick={() =>
                            onAddMusic({
                              musicName: title,
                              linkMusic: ytLinkToWatch,
                              descriptionMusic: description,
                              videoId,
                              thumbnails,
                            })
                          }
                        >
                          <Add /> Agregar
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {/* <div className="col-span-12">
            <Controller
              name="linkMusic"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  type={"link"}
                  title={"Enlace de la música"}
                  required
                />
              )}
            />
          </div>
          <div className="col-span-12">
            <Controller
              name="descriptionMusic"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  type={"textarea"}
                  title={"Comentarios"}
                  minRows={3}
                />
              )}
            />
          </div> */}
          {/* <div className="col-span-12">
            <Button
              type="submit"
              color="success"
              variant="contained"
              className="w-full"
            >
              Enviar
            </Button>
          </div> */}
        </div>
      </form>

      {!loading && (
        <DialogPlayList
          open={openPlaylist}
          onClose={() => setOpenPlaylist(false)}
          onDelete={onDeleteMusic}
          onConfirm={onConfirmPlaylist}
          data={playlistToDj}
        />
      )}
    </PublicTemplate>
  );
};

export default RenderDjSuggestionsPlaylistClient;
