import React, { useEffect, useState } from "react";

import BodyContainer from "./BodyContainer";
import Container from "./Container";
import Head from "./Head";
import Header from "./Header";
import MenuBar from "./MenuBar";

import { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { getUser } from "../services/auth.services";
import DialogSelectSucursal from "./Dialog/DialogSelectSucural";
import FooterMessage from "./FooterMessage";

const Template = (props) => {
  let { title, typeNav = "default", classmaincontainer } = props;
  const history = useHistory();

  const [activeMenuBar, setActiveMenuBar] = useState(true);
  const [activeMenuBarMobile, setActiveMenuBarMobile] = useState(false);

  const [openSelectSucursal, setOpenSelectSucursal] = useState(false);

  const userData = getUser();

  useEffect(() => {
    const activeLocalStorage = JSON.parse(
      localStorage.getItem("activeMenuBar")
    );

    if (activeLocalStorage) {
      setActiveMenuBar(activeLocalStorage.activeDesktop);
      setActiveMenuBarMobile(false);
    }
  }, []);

  useEffect(() => {
    if (
      (!userData?.empresa?.id_empsede ||
        userData?.empresa?.id_empsede === "") &&
      !history.location.pathname.includes("settings")
    ) {
      history.push("/settings/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="container">
      <Toaster />
      <Head>
        <meta charSet="utf-8" />
        <title>{title} | Ubuntu</title>
        <meta name="description" content="Restaurant Ubuntu" />
        <meta property="og:title" content={title} />
      </Head>
      <main className="main">
        <Container>
          <MenuBar
            activeBar={activeMenuBar}
            activeBarMobile={activeMenuBarMobile}
            setActiveBarMobile={setActiveMenuBarMobile}
          />
          <BodyContainer
            classmaincontainer={classmaincontainer || ""}
            head={
              <Header
                activeBar={activeMenuBar}
                activeBarMobile={activeMenuBarMobile}
                setActiveBar={setActiveMenuBar}
                setActiveBarMobile={setActiveMenuBarMobile}
                setOpenSelectSucursal={setOpenSelectSucursal}
              />
            }
          >
            {props.children}
          </BodyContainer>
        </Container>
      </main>

      {/* <FooterMessage /> */}
      <DialogSelectSucursal
        open={openSelectSucursal}
        setOpen={setOpenSelectSucursal}
      />
    </div>
  );
};

export default Template;
