import { CircularProgress, IconButton } from "@mui/material";
import InputDefault from "../../atomic/atoms/input.atoms";
import Template from "../../components/Template";

import { ChevronRight } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  getAiAssistant,
  queryAiAssistant,
} from "../../services/ai-assitant.services";
import { getUser } from "../../services/auth.services";

import Markdown from "react-markdown";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";

//style table
//import style markdown
import "../../assets/styles/MarkDownTable.scss";

const Loading = () => (
  <div className="flex items-center justify-center h-full absolute top-0 bottom-0 left-0 right-0 bg-transparent backdrop-blur-sm z-50">
    <CircularProgress color="secondary" />
    <p className="ml-2 text-gray-600 text-lg">Cargando...</p>
  </div>
);

const AIAsisstant = () => {
  const containerRef = useRef(null); // Referencia al contenedor

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      query: "",
    },
  });

  const nameUser = getUser()?.name || "";

  const [dataQueries, setDataQueries] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleQuery = async (data) => {
    try {
      reset();

      setLoading(true);
      const response = await queryAiAssistant(data);

      setDataQueries([...dataQueries, response]);
      setLoading(false);

      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleGetQueries = async () => {
    try {
      setLoading(true);
      const response = await getAiAssistant();
      setLoading(false);
      setDataQueries(response);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetQueries();
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [dataQueries]); // Escucha cambios en dataQueries

  return (
    <Template
      title="Asistente de IA"
      classmaincontainer="relative h-[calc(100vh-64px)] w-full p-0 m-0"
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full">
        <div
          ref={containerRef}
          className="h-[85%] w-full p-4 bg-gray-200 overflow-y-auto text-sm"
        >
          {loading && <Loading />}

          {dataQueries.map((item, index) => (
            <div key={index} className="mb-6">
              <div className="w-full text-sm mb-2 flex items-center justify-end">
                <div className="mr-4 text-gray-500">
                  {nameUser ? nameUser : "Usuario anónimo"}
                </div>
                <div className="bg-purple-200 p-2 rounded-lg text-gray-800">
                  {item.question_ai}
                </div>
              </div>
              <div className="w-full">
                <Markdown
                  children={item.answer_ai}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    table({ node, ...props }) {
                      return <table {...props} className="markdown-table" />;
                    },
                    code(props) {
                      const { children, className, node, ...rest } = props;
                      const match = /language-(\w+)/.exec(className || "");
                      return match ? (
                        <SyntaxHighlighter
                          {...rest}
                          PreTag="div"
                          children={String(children).replace(/\n$/, "")}
                          language={match[1]}
                        />
                      ) : (
                        <code {...rest} className={className}>
                          {children}
                        </code>
                      );
                    },
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="h-[10%] w-full p-4 border-2 border-gray-200">
          <form
            onSubmit={handleSubmit(handleQuery)}
            autoComplete="off"
            className="flex items-center justify-center gap-3"
          >
            <Controller
              name="query"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  required
                  disabled={loading}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  type="text"
                  title={"Cual es tu pregunta?"}
                />
              )}
            />
            <IconButton
              aria-label="close"
              type="submit"
              disabled={loading}
              className="!bg-purple-800 !text-white"
            >
              <ChevronRight />
            </IconButton>
          </form>
        </div>
      </div>
    </Template>
  );
};

export default AIAsisstant;
