import * as React from "react";
import { Link } from "react-router-dom";

import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  TableFooter,
} from "@mui/material";

import { Delete, Edit, Settings } from "@mui/icons-material";

import NotFound from "./NotFound.organisms";

const CustomTable = ({
  columns,
  rows,
  load = false,
  error = false,
  actionEdit = null,
  actionDelete = null,
  pagination = true,
  onRowSelected = null,
  disableButtons = false,
  rowsPerPagination = 10,
  selectedRows = [],
  rowsFooter = [],
  columnsFooter = null,
}) => {  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    pagination ? rowsPerPagination : 100000
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  const RenderRow = (props) => {
    const { column, row } = props;

    if (!column || !row) {
      return null;
    }

    if (column?.format) {
      return column.format(row[column.id]);
    }

    if (column.redirect) {
      return (
        <Link
          to={column.redirect(row[column.id])}
          className="text-xs underline text-blue-800 hover:text-blue-800 font-semibold"
        >
          {row[column.id]}
        </Link>
      );
    }

    return <div className="text-xs">{row[column.id]}</div>;
  };

  const onRowSelectedAll = () => {
    if (rows?.length === rows.filter((row) => row?.selected).length) {
      rows.map((row) => onRowSelected({ ...row, selected: false }));
    } else {
      rows.map(
        (row) =>
          row?.canBeSelectable && onRowSelected({ ...row, selected: true })
      );
    }
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              {onRowSelected && (
                <TableCell align={"center"} width={35}>
                  <Checkbox
                    onChange={onRowSelectedAll}
                    checked={
                      rows?.length ===
                      rows.filter((row) => row?.selected).length
                    }
                  />
                </TableCell>
              )}
              {columns.map((column, idx) => {
                return (
                  <TableCell
                    key={idx}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="!font-semibold !text-xs !text-gray-800"
                  >
                    {column.label}
                  </TableCell>
                );
              })}

              {(actionEdit || actionDelete) && (
                <TableCell
                  align={"center"}
                  colSpan={actionEdit && actionDelete ? 2 : 1}
                >
                  <Settings />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {onRowSelected && (
                      <TableCell>
                        <Checkbox
                          disabled={!row?.canBeSelectable}
                          onChange={() =>
                            onRowSelected({ ...row, selected: !row.selected })
                          }
                          checked={row?.selected}
                        />
                      </TableCell>
                    )}

                    {columns.map((column, idx) => (
                      <TableCell key={idx} align={column.align}>
                        <RenderRow column={column} row={row} />
                      </TableCell>
                    ))}

                    {actionEdit && (
                      <TableCell align={"center"} width={35}>
                        <IconButton
                          color="warning"
                          onClick={() => actionEdit(row)}
                          disabled={disableButtons}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    )}

                    {actionDelete && (
                      <TableCell align={"center"} width={35}>
                        <IconButton
                          color="error"
                          onClick={() => actionDelete(row)}
                          disabled={disableButtons}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>

          {rowsFooter.length > 0 && columnsFooter.length > 0 && (
            <TableFooter
              style={{
                position: "sticky",
                bottom: 0,
                borderTop: "1px red solid",
                backgroundColor: "#fbfafd",
              }}
            >
              {rowsFooter.map((row, idx) => {
                return (
                  <TableRow key={idx}>
                    {columnsFooter.map((column, idx) => {
                      const value = row[column.id];

                      if (column?.format) {
                        return (
                          <TableCell
                            key={idx}
                            align={column.align}
                            className="!font-bold "
                          >
                            {column.format(value)}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={idx}
                          align={column.align}
                          className="!font-bold"
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          labelRowsPerPage="Filas por página"
          rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default CustomTable;
