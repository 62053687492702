import React from "react";

import TableProducto from "./Table";

import { useDispatch, useSelector } from "react-redux";
import { actionTypesPlato } from "../../../redux/plato/actions";

import ContainerInput from "../../../components/ContainerInput";

import ModalDeleteProduct from "./Dialog/ConfirmDelete/confirmDeleteProduct";
import ModalAddEdit from "./Dialog/CrudForm";
import ModalProducts from "./Dialog/Insumos";

import "./style.plato.scss";

import { Add } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

const Productos = () => {
  const dispatch = useDispatch();

  const { dataPlato } = useSelector((state) => state.reducer_plato);
  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const onOpenProduct = (data) =>
    dispatch({
      type: actionTypesPlato.openModalAddEdit_plato,
      payload: data,
    });

  const addActiveButton = !dataSelected_categoria?.id_categoriapla;

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={addActiveButton}
            style={{
              opacity: `${addActiveButton ? "0.5" : "1"} !important`,
            }}
            onClick={() => {
              onOpenProduct(true);
            }}
          >
            Agregar <Add />
          </Button>
        </Grid>
      </Grid>
      <ContainerInput
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: "0px 0px",
          textAlign: "center",
        }}
      >
        <TableProducto dataPlato={dataPlato} />
      </ContainerInput>

      <ModalAddEdit />
      <ModalDeleteProduct />
      <ModalProducts />
    </>
  );
};

export default Productos;
