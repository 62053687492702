import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import {
  Autocomplete,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import { get_producto } from "../../../../../../services/productos.services";

import { useDispatch, useSelector } from "react-redux";
import { actionTypesPlato } from "../../../../../../redux/plato/actions";
import { formatMoney } from "../../../../../../functions/formatMoney";

/*
  TMP TABLE
*/

const TmpAddRow = (props) => {
  let { data, setData, handleRemoveRow } = props;

  const handleChange = (val) => {
    let { name, value } = val.target;

    if (value === "") {
      data[name] = "";
      setData({ ...data });
      return;
    }

    // Asegúrate de convertir correctamente a número flotante
    const newValue = parseFloat(value);

    // Si no es un número válido, detén la ejecución
    if (isNaN(newValue)) {
      data[name] = "";
      setData({ ...data });
      return;
    }

    // Realiza los cálculos
    const precio = data.prod_precio ?? 0;

    data[name] = newValue;
    data["prod_medida_precio"] = precio * newValue;

    // Actualiza el estado
    setData({ ...data }); // Usa un spread para evitar mutaciones directas
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell align={"left"}>{data.prod_cod}</TableCell>
      <TableCell align={"left"}>
        {data.cat_nombre}{" "}
        {data?.name_subcategoria && `- ${data.name_subcategoria}`}
      </TableCell>
      <TableCell align={"left"}>{data.prod_nombre}</TableCell>
      <TableCell align={"left"}>{data.prod_descripcion}</TableCell>
      <TableCell align={"left"}>
        {data.value_medida} ({data.name_medida})
      </TableCell>
      <TableCell align={"left"}>
        {formatMoney(data?.prod_precio ?? 0)}
      </TableCell>
      <TableCell align={"left"}>
        <TextField
          fullWidth
          name="prod_medida_value"
          variant="outlined"
          className={`InputDefault`}
          type={"number"}
          value={data?.prod_medida_value ?? 0}
          onChange={handleChange}
          step="any"
          inputProps={{
            min: 0.01,
          }}
        />
      </TableCell>
      <TableCell align={"left"}>
        {formatMoney(data?.prod_medida_precio ?? 0)}
      </TableCell>
      <TableCell align={"left"}>
        <IconButton color="error" onClick={() => handleRemoveRow(data)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const TableTmpAdd = (props) => {
  let { data, setData, numSelected, handleRemoveRow } = props;

  if (numSelected === 0) {
    return false;
  }

  let rows = data.map((el) => ({ ...el, prod_medida_precio: el?.prod_medida_valor * el?.prod_precio }));

  const totXporcion = rows.reduce((acc, cur) => {
    return acc + cur?.prod_medida_precio ?? 0;
  }, 0);

  const handleSetData = (newData) => {
    let filterIndex = data.findIndex((el) => el.prod_id === newData.prod_id);
    data[filterIndex] = newData;
    setData(data);
  };

  return (
    <>
      <Paper
        variant="outlined"
        className="mt-2"
        style={{ padding: 10, background: "#f7f7f7" }}
      >
        {numSelected > 0 && (
          <Typography
            color="inherit"
            variant="h3"
            className="!text-sm"
            component="div"
          >
            Insumos seleccionados: {numSelected}
          </Typography>
        )}
      </Paper>
      <Paper variant="outlined" style={{ marginTop: 10 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"} style={{ minWidth: 80 }}>
                Código
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Categoría
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 180 }}>
                Producto
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 180 }}>
                Descripción
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 80 }}>
                Medida por unidad
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 80 }}>
                Precio por unidad
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 50 }}>
                Cantidad
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 50 }}>
                Coste total
              </TableCell>
              <TableCell align={"left"}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TmpAddRow
                data={row}
                setData={handleSetData}
                handleRemoveRow={handleRemoveRow}
                key={idx}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>

      {totXporcion > 0 && (
        <div className="mt-2">
          <Typography variant="h6" className="!text-sm">
            Total de coste por insumos: <b>{formatMoney(totXporcion)}</b>
          </Typography>
        </div>
      )}
    </>
  );
};

const TableInsumos = () => {
  const dispatch = useDispatch();

  const { paramSelected_product } = useSelector((state) => state.reducer_plato);

  const [dataInsumo, setDataInsumos] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const onSetParamSelected = (data) =>
    dispatch({
      type: actionTypesPlato.setParamSelected_product,
      payload: data,
    });

  const handleSelectedRow = (data) => {
    if (!data) return;

    const code = data.split(" - ")[0];

    let filterData;

    filterData = dataInsumo.data.find((item) => item.prod_cod === code);

    if (!filterData) {
      filterData = dataInsumo.data.find(
        (item) => Number(item?.prod_id) === Number(code)
      );
    }

    const doesProductExist = paramSelected_product.some(
      (item) => Number(item.prod_id) === Number(filterData.prod_id)
    );

    if (doesProductExist) return;

    onSetParamSelected([...paramSelected_product, { ...filterData }]);
  };

  const handleRemoveRow = (data) => {
    const newData = paramSelected_product.filter(
      (item) => item.prod_id !== data.prod_id
    );
    onSetParamSelected(newData);
  };

  React.useEffect(() => {
    async function obtainDataInsumo() {
      try {
        setDataInsumos({ ...dataInsumo, load: true, data: [], err: false });
        const response = await get_producto();

        setDataInsumos({
          ...dataInsumo,
          load: false,
          data: response,
          err: false,
        });
      } catch (err) {
        setDataInsumos({ ...dataInsumo, load: false, data: [], err: true });
      }
    }

    obtainDataInsumo();

    return null;
  }, []);

  return (
    <>
      <form autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={7} className="flex gap-2">
            <Autocomplete
              freeSolo
              fullWidth
              disabled={dataInsumo.load}
              id="autocomplete-search"
              disableClearable
              options={dataInsumo.data.map(
                (el) =>
                  `${el?.prod_cod ?? el?.prod_id} - ${el?.prod_nombre ?? ""} (${
                    el?.cat_nombre ?? ""
                  })`
              )}
              onChange={(event, newValue) => handleSelectedRow(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Buscar por nombre producto" />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <TableTmpAdd
        numSelected={paramSelected_product.length}
        data={paramSelected_product}
        setData={onSetParamSelected}
        handleRemoveRow={handleRemoveRow}
      />
    </>
  );
};

export default TableInsumos;
