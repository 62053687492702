import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const getSuggestDj = () => {
  const url = `${baseurl}/api/suggest-dj/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createSuggestDj = (params) => {
  const url = `${baseurl}/api/suggest-dj/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateSuggestDj = (params) => {
  const url = `${baseurl}/api/suggest-dj/update`;

  return axios
    .patch(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createSuggestDjPlaylist = (params) => {
  const url = `${baseurl}/api/suggest-dj/playlist/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};