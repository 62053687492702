import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { Alert, IconButton, Tab, Tabs } from "@mui/material";
import {
  getSuggestDj,
  updateSuggestDj,
} from "../../services/suggest-dj.services";
import { getUser } from "../../services/auth.services";
import { CheckCircle, Download, OpenInNew } from "@mui/icons-material";
import InputDefault from "../../atomic/atoms/input.atoms";
import { getUrlFront } from "../../functions/utilidades.function";
import toast from "react-hot-toast";

const DjSuggestions = (props) => {
  let { socket } = props;

  const [dataSuggestions, setDataSuggetions] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [valueTabs, setValueTabs] = React.useState(0);

  const url = getUrlFront();

  const empId = getUser()?.empresa?.id_emp || 0;
  const LINK_DJ_SUGGESTIONS = `${url}/dj-suggestions/${empId}`;

  const dataLive = dataSuggestions.filter(
    (el) => el?.type_suggestdj === "live" && el?.reproduced_suggestdj === 0
  );

  const dataHistory = dataSuggestions.filter(
    (el) => el?.type_suggestdj === "live" && el?.reproduced_suggestdj === 1
  );

  // const filterTime = (data) => {
  //   const oneHourAgoUTC = dayjs.utc().subtract(4, "hour");

  //   return data.filter((el) => {
  //     const recordTimeUTC = dayjs.utc(el.createdat_suggestdj);

  //     return recordTimeUTC.isAfter(oneHourAgoUTC);
  //   });
  // };

  const fetchDataSuggestions = async () => {
    try {
      setLoad(true);
      let response = await getSuggestDj();

      response = response.map((el) => ({
        ...el,
        canBeSelectable: true,
      }));

      setDataSuggetions(response);

      setLoad(false);
    } catch (error) {
      console.error("Error en fetchDataSuggestions: ", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleMarkAsHeard = async (id) => {
    const data = dataLive.find((item) => item.id_suggestdj === id);

    await updateSuggestDj({
      id_suggestdj: id,
      reproduced_suggestdj: 1,
    });

    toast.success(`${data.music_description} se marcó como oído.`, {
      position: "top-center",
      autoClose: 6000,
    });

    setDataSuggetions((prev) => {
      return prev.map((el) => {
        if (el.id_suggestdj === id) {
          return {
            ...el,
            reproduced_suggestdj: 1,
          };
        }

        return el;
      });
    });
  };

  useEffect(() => {
    fetchDataSuggestions();
  }, []);

  useEffect(() => {
    socket.on("suggest-dj-created", async (element) => {
      const { data } = element;

      setDataSuggetions(data);
    });

    return null;
  }, []);

  // /#/dj-suggestions/2

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <div className="flex flex-row justify-between w-96">
            <InputDefault
              title="Link de sugerencias en vivo"
              type="text"
              value={LINK_DJ_SUGGESTIONS}
              readOnly
            />
            <IconButton
              style={{ marginLeft: 5 }}
              color="warning"
              onClick={() => window.open(LINK_DJ_SUGGESTIONS, "_blank")}
            >
              <OpenInNew />
            </IconButton>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4">
          <ContainerInput style={{ padding: 10 }}>
            Número músicas pedidas: {dataLive.length}
          </ContainerInput>
        </div>
        <Alert severity="info" className="col-span-12 md:col-span-8" b>
          <p>
            En esta sección podrás ver todas las recomendaciones de los
            clientes a tiempo real, con 6 horas de antigüedad.
          </p>
        </Alert>
        <div className="col-span-12 md:col-span-12">
          <Tabs value={valueTabs} onChange={handleChange}>
            <Tab label="En vivo" />
            <Tab label="Historial" />
          </Tabs>
          <ContainerInput className="p-0 mt-4">
            {valueTabs === 0 && (
              <CustomTable
                columns={[
                  {
                    id: "id_suggestdj",
                    label: "Reproducido",
                    minWidth: 80,
                    align: "center",
                    format: (el) => {
                      return (
                        <IconButton onClick={() => handleMarkAsHeard(el)}>
                          <CheckCircle className="text-green-500" />
                        </IconButton>
                      );
                    },
                  },
                  {
                    id: "numbertimes_suggestdj",
                    label: "# veces",
                    minWidth: 80,
                    align: "center",
                    format: (el) => {
                      return <span className="uppercase">{el}</span>;
                    },
                  },
                  {
                    id: "video_id",
                    label: "Descargar",
                    minWidth: 80,
                    align: "center",
                    format: (el) => {
                      if (!el) {
                        return null;
                      }

                      return (
                        <a
                          href={`https://www.y2mate.com/youtube/${el}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          <Download />
                        </a>
                      );
                    },
                  },
                  {
                    id: "id_cliente",
                    label: "Nombre",
                    minWidth: 150,
                    align: "left",
                    format: (el) => {
                      const userData = dataLive.find(
                        (item) => item.id_cliente === el
                      );

                      if (!userData) {
                        return null;
                      }

                      return (
                        <span className="uppercase">
                          {userData?.user_name} {userData?.user_lastname}
                        </span>
                      );
                    },
                  },
                  {
                    id: "music_description",
                    label: "Descripción de la música",
                    minWidth: 250,
                    align: "left",
                    format: (el) => {
                      return <span className="uppercase">{el}</span>;
                    },
                  },
                  {
                    id: "link_suggestdj",
                    label: "Link de la música",
                    minWidth: 150,
                    align: "left",
                    format: (el) => {
                      return (
                        <a
                          href={el}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          {el}
                        </a>
                      );
                    },
                  },
                  {
                    id: "text_suggestdj",
                    label: "Comentarios",
                    minWidth: 300,
                    align: "left",
                    format: (el) => {
                      return <span className="normal-case">{el}</span>;
                    },
                  },
                  {
                    id: "createdat_suggestdj",
                    label: "Fecha",
                    minWidth: 160,
                    align: "left",
                    format: (el) => {
                      return dayjs(el).utc().format("DD/MM/YYYY HH:mm");
                    },
                  },
                ]}
                rows={dataLive}
                load={load}
                error={false}
                pagination={false}
              />
            )}
            {valueTabs === 1 && (
              <CustomTable
                columns={[
                  {
                    id: "numbertimes_suggestdj",
                    label: "# veces",
                    minWidth: 80,
                    align: "center",
                    format: (el) => {
                      return <span className="uppercase">{el}</span>;
                    },
                  },
                  {
                    id: "video_id",
                    label: "Descargar",
                    minWidth: 80,
                    align: "center",
                    format: (el) => {
                      if (!el) {
                        return null;
                      }

                      return (
                        <a
                          href={`https://www.y2mate.com/youtube/${el}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          <Download />
                        </a>
                      );
                    },
                  },
                  {
                    id: "id_cliente",
                    label: "Nombre",
                    minWidth: 150,
                    align: "left",
                    format: (el) => {
                      const userData = dataLive.find(
                        (item) => item.id_cliente === el
                      );

                      if (!userData) {
                        return null;
                      }

                      return (
                        <span className="uppercase">
                          {userData?.user_name} {userData?.user_lastname}
                        </span>
                      );
                    },
                  },
                  {
                    id: "music_description",
                    label: "Descripción de la música",
                    minWidth: 250,
                    align: "left",
                    format: (el) => {
                      return <span className="uppercase">{el}</span>;
                    },
                  },
                  {
                    id: "link_suggestdj",
                    label: "Link de la música",
                    minWidth: 150,
                    align: "left",
                    format: (el) => {
                      return (
                        <a
                          href={el}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          {el}
                        </a>
                      );
                    },
                  },
                  {
                    id: "text_suggestdj",
                    label: "Comentarios",
                    minWidth: 300,
                    align: "left",
                    format: (el) => {
                      return <span className="normal-case">{el}</span>;
                    },
                  },
                  {
                    id: "createdat_suggestdj",
                    label: "Fecha",
                    minWidth: 160,
                    align: "left",
                    format: (el) => {
                      return dayjs(el).utc().format("DD/MM/YYYY HH:mm");
                    },
                  },
                ]}
                rows={dataHistory}
                load={load}
                error={false}
                pagination={false}
              />
            )}
          </ContainerInput>
        </div>
      </div>
    </Template>
  );
};

export default DjSuggestions;
