import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material/";

import { Close, Delete, Edit } from "@mui/icons-material";

import "moment/locale/es";
import { Controller, useForm } from "react-hook-form";
import useClients from "../../hooks/useClients";
import { get_clientes_id } from "../../services/clientes.services";
import {
  add_reserva_person,
  delete_clientReserva,
  get_clientReserva,
} from "../../services/reservas.services";
import Swal from "sweetalert2";

const FormPerson = (props) => {
  const { INITIAL_VALUES, id_reserva, useFormPerson, fetchClientReserva } =
    props;

  const { handleSubmit, control, reset, watch, setValue } = useFormPerson;

  const dataClients = useClients("telf");

  const [loadingForm, setLoadingForm] = React.useState(false);

  const filter = createFilterOptions();

  const handleAddClient = async (data) => {
    try {
      setLoadingForm(true);
      await add_reserva_person({
        ...data,
        idReserva: id_reserva,
      });
      setLoadingForm(false);

      await fetchClientReserva();

      reset(INITIAL_VALUES);
    } catch (err) {
      setLoadingForm(false);
    }
  };

  const handleUpdateParams = async (data) => {
    /**
     * when is empty
     */

    if (!data) {
      setValue("id_cliente", "");
      setValue("correo_cliente", "");
      setValue("nombre_cliente", "");
      setValue("apepaterno_cliente", "");
      setValue("apematerno_cliente", "");
      setValue("telf_cliente", "");
      return;
    }

    /**
     * when email not found
     */

    if (data.new) {
      setValue("telf_cliente", data.labelNew);
      setValue("correo_cliente", "");
      setValue("id_cliente", "");
      setValue("nombre_cliente", "");
      setValue("apepaterno_cliente", "");
      setValue("apematerno_cliente", "");

      return;
    }

    /**
     * when search email exist
     */

    const response = await get_clientes_id(data.value);

    setValue("id_cliente", response.id_cliente);
    setValue("correo_cliente", response.correo_cliente);
    setValue("nombre_cliente", response.nombre_cliente);
    setValue("apepaterno_cliente", response.apepaterno_cliente);
    setValue("apematerno_cliente", response?.apematerno_cliente);
    setValue("telf_cliente", response.telf_cliente);
  };

  return (
    <form onSubmit={handleSubmit(handleAddClient)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={12}>
          <Autocomplete
            value={watch("telf_cliente")}
            options={dataClients}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              const isExisting = options.some(
                (option) => inputValue === option.label
              );

              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: `Agregar nuevo: "${inputValue}"`,
                  labelNew: inputValue,
                  new: true,
                });
              }

              return filtered;
            }}
            // eslint-disable-next-line no-self-compare
            isOptionEqualToValue={(option, value) => true === true}
            onChange={(event, newValue) => {
              handleUpdateParams(newValue);
              return;
            }}
            renderInput={(params) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ marginRight: "5px" }}
                >
                  +56
                </Typography>
                <TextField
                  {...params}
                  type="text"
                  label="Teléfono"
                  size="small"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 9);
                  }}
                  min={9}
                  disabled={loadingForm}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="correo_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                required
                type="email"
                label="Correo electrónico"
                size="small"
                disabled={loadingForm}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="nombre_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                required
                value={value}
                onChange={onChange}
                type="text"
                label="Nombre"
                size="small"
                disabled={loadingForm}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="apepaterno_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                required
                fullWidth
                value={value}
                onChange={onChange}
                type="text"
                label="Apellido paterno"
                size="small"
                disabled={loadingForm}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="apematerno_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                type="text"
                label="Apellido materno"
                size="small"
                disabled={loadingForm}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="warning"
            style={{ width: "30%", minWidth: "max-content" }}
            type="submit"
            disabled={loadingForm}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const TablePerson = (props) => {
  const { dataClientReserva, loading, onEdit, onDelete } = props;

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  return (
    <Table
      size="small"
      style={{
        border: "1px #eee solid",
        position: "sticky",
        top: 0,
        background: "white",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ background: "#eee", textAlign: "center" }}>
            Nombres
          </TableCell>
          <TableCell style={{ background: "#eee", textAlign: "center" }}>
            Correo electrónico
          </TableCell>
          <TableCell style={{ background: "#eee", textAlign: "center" }}>
            Teléfono
          </TableCell>
          <TableCell
            style={{ background: "#eee", textAlign: "center" }}
          ></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataClientReserva.map((row, index) => (
          <TableRow key={index}>
            <TableCell style={{ textAlign: "center" }}>
              {row.nombre_cliente} {row.apepaterno_cliente}{" "}
              {row?.apematerno_cliente}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              {row.correo_cliente}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              {row.telf_cliente}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <IconButton
                color="warning"
                onClick={() => onEdit(row.id_cliente)}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => onDelete(row.id_cliente)}
                style={{ marginLeft: 10 }}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const DialogAddPerson = ({ open, setOpen, data }) => {
  const { id_reserva } = data;
  const [dataClientReserva, setDataClientReserva] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const INITIAL_VALUES = {
    id_cliente: "",
    nombre_cliente: "",
    apepaterno_cliente: "",
    apematerno_cliente: "",
    telf_cliente: "",
    correo_cliente: "",
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    defaultValues: INITIAL_VALUES,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetClientReserva = async () => {
    try {
      setLoading(true);
      const response = await get_clientReserva(id_reserva);
      setLoading(false);
      setDataClientReserva(response);
    } catch (err) {}
  };

  const handleOnShowEdit = (id) => {
    const data = dataClientReserva.find((item) => item.id_cliente === id);
    setValue("id_cliente", data?.id_cliente);
    setValue("nombre_cliente", data?.nombre_cliente);
    setValue("apepaterno_cliente", data?.apepaterno_cliente);
    setValue("apematerno_cliente", data?.apematerno_cliente);
    setValue("telf_cliente", data?.telf_cliente);
    setValue("correo_cliente", data?.correo_cliente);
  };

  const handleOnShowDelete = (id) => {
    setOpen(false);

    const data = dataClientReserva.find((item) => item.id_cliente === id);

    Swal.fire({
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      title: "¿Estás seguro?",
      text: `Eliminarás a ${data?.nombre_cliente} ${data?.apepaterno_cliente} ${data?.apematerno_cliente}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await delete_clientReserva(data?.id_reservclient, data?.id_cliente);

        setOpen(true);
      }
    });
  };

  React.useEffect(() => {
    if (open) {
      handleGetClientReserva();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>
          Agregar personas a la reserva
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={4}>
              <FormPerson
                INITIAL_VALUES={INITIAL_VALUES}
                fetchClientReserva={handleGetClientReserva}
                useFormPerson={{
                  control,
                  reset,
                  watch,
                  setValue,
                  handleSubmit,
                }}
                id_reserva={id_reserva}
              />
            </Grid>
            <Grid item xs={8}>
              <TablePerson
                loading={loading}
                dataClientReserva={dataClientReserva}
                onEdit={handleOnShowEdit}
                onDelete={handleOnShowDelete}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogAddPerson;
